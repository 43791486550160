<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <div class="row justify-between q-gutter-md">
          <q-select
            :options="$LIST_JENJANG"
            v-model="selJenjang"
            @input="
              searchTerm = '';
              selTahun = 'SEMUA TAHUN';
              getMasuk();
              getList();
            "
            outlined
            label="Jenjang"
            style="min-width: 130px;"
          ></q-select>
          <q-select
            :options="listTahun"
            v-model="selTahun"
            @input="
              searchTerm = '';
              getList();
            "
            outlined
            label="Tahun Masuk"
            style="min-width: 130px;"
          ></q-select>
        </div>

        <q-input
          outlined
          label="Cari Siswa/NISN"
          v-model="searchTerm"
          @input="getList"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        id="table_siswa"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nisn</th>
            <th>nama</th>
            <th>gender</th>
            <th>tanggal lahir</th>
            <th>tahun masuk</th>
            <th style="width:20px;"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in listSiswa" :key="i">
            <td>{{ (pagination.current - 1) * pagination.limit + i + 1 }}</td>
            <td>{{ val.nisn }}</td>
            <td>{{ val.nama }}</td>
            <td>{{ val.gender }}</td>
            <td>{{ val.tanggal_lahir | moment("DD MMM YYYY") }}</td>
            <td>{{ val.tahun_masuk }}</td>
            <td>
              <q-btn
                flat
                dense
                icon="menu_book"
                :to="'/akademikraportsiswa/' + val.id"
              ></q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions>
        <q-pagination
          v-model="pagination.current"
          :max="pagination.max"
          input
          input-class="text-orange-10"
          @input="getList"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listTahun: [],
      selTahun: "SEMUA TAHUN",

      selJenjang: this.$LIST_JENJANG[0],
      listSiswa: [],
      fullListSiswa: [],
      searchTerm: "",
      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getMasuk();
    await this.getList();
    this.$q.loading.hide();
  },
  methods: {
    async getMasuk() {
      let temp = [];
      let resp = await this.$http.get(
        `/akademik/riwayat_raport/gettahun/${this.selJenjang}`
      );
      for (let item of resp.data) {
        if (item.tahun_masuk) {
          temp.push(item.tahun_masuk);
        }
      }
      temp.unshift("SEMUA TAHUN");
      this.listTahun = temp;
    },
    async getList() {
      this.$q.loading.show({
        message: "sedang mengambil data",
      });

      var src = "%25";
      if (this.searchTerm != "") src = this.searchTerm;

      let offset = (this.pagination.current - 1) * this.pagination.limit;

      await this.$http

        .get(
          `/akademik/riwayat_raport/getlist/${this.selJenjang}/${this.selTahun}/${src}/${this.pagination.limit}/${offset}`,
          this.$hlp.getHeader()
        )

        .then((resp) => {
          this.listSiswa = resp.data;
          this.$q.loading.hide();
        });

      await this.$http
        .get(
          `/akademik/riwayat_raport/getlist/${this.selJenjang}/${this.selTahun}/${src}`,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.fullListSiswa = resp.data;
          this.pagination.max = Math.ceil(
            this.fullListSiswa.length / this.pagination.limit
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
